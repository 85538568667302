import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
//import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'

class FifaRankings extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiIntTopXStats.edges
    const rdata = this.props.data.allSheetRonaldoIntTopXStats.edges

    return (

      <Layout>
        <SEO
          title={"Messi and Ronaldo Stats by FIFA Ranking - International Goals"}
          description={`How many of Messi and Ronaldo's international goals have come against the teams in the Top 10 of the FIFA Rankings? Or against the lowest ranked teams?`}
          canonicalPath={`/international-stats/fifa-rankings/`}
        />

        <h1>
          <span className="sr-only">Messi and Ronaldo International</span> Stats by FIFA Ranking
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'All Internationals', 'url': 'none', 'active': true },
            { 'name': 'Competitive Only', 'url': '/international-stats/fifa-rankings-competitive/' },
          ]}
        />

        <CompetitionBlock competition="vs Top 3" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 5" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 10" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 20" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 30" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 40" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 50" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 51-75" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 76-100" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 101-150" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 151-200" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 201-250" type="int" modalSubTitle="All Internationals" subtitle="All International Games" mdata={mdata} rdata={rdata} />

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiIntTopXStats(filter: {pgKey: {regex: "/int-ranking/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          avgRating
        }
      }
    }
    allSheetRonaldoIntTopXStats(filter: {pgKey: {regex: "/int-ranking/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          avgRating
        }
      }
    }
  }
`

export default FifaRankings
